<template>
  <div
    class="custom-quill-toolbar pa-0"
  >
    <div
      class="custom-quill-toolbar__content"
    >
      <div 
        id="quill-toolbar"
        class="d-flex justify-center"
      >
        <template
          v-for="(section, sectionIndex) in items"
        >
          <div
            class="custom-quill-toolbar__section"
            :key="`section-${sectionIndex}`"
          >
            <template
              v-for="(item, itemIndex) in section"
            >
              <div
                
                class="custom-quill-toolbar__item"
                :key="`item-${item.id}-${itemIndex}`" 
              >
                <button
                  v-if="!item.multiple"
                  :class="`ql-${item.id}`"
                  :value="item.value"
                  :title="item.label"
                />
                <select
                  v-else
                  :title="item.label"
                  :disabled="disabled"
                  :class="`ql-${item.id}`"
                >
                  <option 
                    v-for="(option, optionIndex) in item.options"
                    :key="`item-${item.id}-option-${optionIndex}`"
                    :selected="item.selected"
                    :value="option.value"
                  />
                </select>
              </div>
            </template>
          </div>
          <div 
            class="custom-quill-toolbar__divider"
            :key="`${sectionIndex}-divider`"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    localHistory: {},
    editorReady: {},
    disabled: {
      type: Boolean
    }
  },
  name: 'QuillToolbar',
  mounted() {
    this.$emit('mounted')
  },
  computed: {
    history() {
      return this.$parent.editor.history
    },
    items() {
      return [
        [
          {
            id: 'undo'
          },
          {
            id: 'redo'
          }
        ],
        [
          {
            id: 'header',
            multiple: true,
            options: [
              {
                selected: true
              },
              {
                value: 1
              },
              {
                value: 2
              },
              {
                value: 3
              },
              {
                value: 4
              },
              {
                value: 5
              },
              {
                value: 6
              }
            ]
          },
        ],
        [
          {
            id: 'bold',
            label: 'Bold'
          },
          {
            id: 'italic',
            label: 'Italic'
          },
          {
            id: 'underline',
            label: 'Underline'
          },
          {
            id: 'strike',
            label: 'Strike'
          },
          {
            id: 'color',
            multiple: true,
            label: 'Color',
            options: []
          },
          {
            id: 'background',
            multiple: true,
            label: 'Background color',
            options: []
          },
          {
            id: 'code-block',
            label: 'Code'
          },
          {
            id: 'clean',
            label: 'Remove Styling'
          }
        ]
      ]
    }
  }
}
</script>
<style lang="scss">
.vue-tooltip {
  z-index: 10001 !important;
}

.custom-quill-toolbar {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 54px;

  &__item {

    background-color: transparent;
    transition: all 0.3s;

    .ql-picker-label {
      display: flex;
      align-items: center;
    }
    

    button,
    .ql-picker {
      height: 28px !important;
      border-radius: 4px;
      svg {
        height: 20px;
        width: 20px;
      }
    }

    .ql-stroke {
      stroke: #525a66;
    }

    .ql-fill {
      fill: #525a66 !important;
    }

    margin: 0 4px 0 0;

    &:last-child {
      margin: 0px;
    }
  }
  button:hover, 
  button:hover, 
  button:focus, 
  button:focus, 
  button.ql-active, 
  button.ql-active, 
  .ql-picker-label:hover, 
  .ql-picker-label:hover, 
  .ql-picker-label.ql-active, 
  .ql-picker-label.ql-active, 
  .ql-picker-item:hover,
  .ql-picker-item:hover, 
  .ql-picker-item.ql-selected, 
  .ql-picker-item.ql-selected {
    color: #090b0e !important;
    background-color: #f5f7fa;

    .ql-fill {
      fill: #090b0e !important; 
    }
    .ql-stroke {
      stroke: #090b0e !important;
    }
  }

  &__disabled {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__section {
    display: flex;
    margin: 0 12px;
    align-items: center;
  }

  &__divider {
    height: 28px;
    margin: 0 8px;
    width: 1px;
    background-color: #ced2d9;
  }

  &__section:first-child {
    margin-left: 0px;
  }

  &__content {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    & > div {
      padding: 4px 16px;
      display: flex;
      align-items: center;
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
  }
}
</style>